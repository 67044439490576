<template>
  <div></div>
</template>
<script>
import { PNP_ACCOUNT_VIEW } from '@/constants'

//NOTE: this view is needed for routing. The functionality is in PNP account modal.

export default {
  name: PNP_ACCOUNT_VIEW,
}
</script>
